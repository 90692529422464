import * as React from "react"

import Layout from '../components/layout'
import PlainPage from "../components/plainPage"
import Seo from '../components/seo'


export default function NotFoundPage() {
  return (
    <Layout>
      <Seo title='Sara Fashionwear' />
      <PlainPage headline='Thank you for reaching out' subline='Message sent' body='Thank you for your interest in Sara Fashionwear. We will get back to you shortly.'/>
    </Layout>
  )
}